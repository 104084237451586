import Image from "next/image";

export default function LinkWhite({ title, linkIcon = '/images/arrow-diagonal-black.svg', link = '#', myClass = ''}) {
    return (
        <a href={link} className={`flex items-center justify-center gap-1 bg-white py-[14px] px-[28px] rounded-full ${myClass}`}>
            <span className="text-[#02091C] font-bold text-base">{title}</span>
            <Image alt="icon"
                src={linkIcon}
                width={20}
                height={20}
                priority={false}
                quality={100}
                className="w-[20px] h-[20px] object-cover"
            />
        </a>
    )
}