'use client'

import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import LinkGradient from '../../components/link-button/link-gradient';

const BlocksSolution = () => {
    const [activeTab, setActiveTab] = useState("all");
    const [hoveredItemId, setHoveredItemId] = useState(null);

    const data = {
        all: [],
        pickupSolutions: [
            { id: 1, title: "Ghi âm cuộc gọi người giao hàng (Shipper)" },
            { id: 2, title: "Giải pháp phân hàng tự động" },
            { id: 3, title: "Giải pháp AI phân tích dữ liệu" },
            { id: 4, title: "Cá nhân hóa dịch vụ khách hàng" },
            { id: 5, title: "Hệ thống GPS và IoT trong theo dõi hàng hóa" },
        ],
        transitSolutions: [
            { id: 6, title: "Quản lý rủi ro trong chuỗi cung ứng" },
            { id: 7, title: "Quản lý tồn kho hiệu quả" },
            { id: 8, title: "Dịch vụ logistics bên thứ ba (3PL) và thứ tư (4PL)" },
            { id: 9, title: "Logistics ngược (Reverse Logistics)" },
            { id: 10, title: "Giải pháp giám sát tải trọng hàng hoá" },
        ],
        deliverySolutions: [
            { id: 11, title: "Đa phương thức vận tải (Intermodal Transport)" },
            { id: 12, title: "Blockchain trong logistics" },
            { id: 13, title: "Cá nhân hóa dịch vụ khách hàng" },
            { id: 14, title: "Quản lý rủi ro trong chuỗi cung ứng" },
            { id: 15, title: "Hệ thống GPS và IoT trong theo dõi hàng hóa" },
        ],
        shopSolutions: [
            { id: 16, title: "Item 16" },
            { id: 17, title: "Item 17" },
            { id: 18, title: "Item 18" },
            { id: 19, title: "Item 19" },
            { id: 20, title: "Item 20" },
        ],
    };

    // Merge items from all tabs into "All", limited to 15 items
    data.all = [
        ...data.pickupSolutions.slice(0, 5),
        ...data.transitSolutions.slice(0, 5),
        ...data.deliverySolutions.slice(0, 5),
        ...data.shopSolutions.slice(0, 5),
    ].slice(0, 15);

    function formatTabName(tabKey) {
        switch (tabKey) {
          case "all":
            return "Tất cả";
          case "pickupSolutions":
            return "Giải pháp nhận hàng";
          case "transitSolutions":
            return "Giải pháp trung chuyển";
          case "deliverySolutions":
            return "Giải pháp phát hàng";
          case "shopSolutions":
            return "Giải pháp cho Shop";
          default:
            return "";
        }
    }
    
    return (
        <>
            <div className="container mx-auto mt-9 md:mt-28 p-4">
                <p className="text-[#02091C] font-bold text-lg -tracking-[0.03] mb-4">{'Giải pháp'}</p>
                <p className="text-[#02091C] font-bold text-2xl md:text-[40px] tracking-tighter md:leading-[52px] text-justify">
                    {'Ứng dụng công nghệ vào giải pháp vận chuyển thông minh, giúp tối ưu hoá lộ trình và giảm thiểu chi phí và thời gian giao hàng. Nâng cao hiệu suất việc cho doanh nghiệp vận tải'}
                </p>
                <LinkGradient title={'Giải pháp phù hợp nhất với bạn'} myClass="md:!w-96 mt-10" />
            </div>
            <div className="container mx-auto md:mt-16 p-4">
                {/* Tabs */}
                <div className="flex border-b border-gray-200 overflow-x-auto md:overflow-x-visible">
                    {Object.keys(data).map((tabKey) => (
                    <button
                        key={tabKey}
                        onClick={() => setActiveTab(tabKey)}
                        className={`px-6 py-3 text-2xl font-bold whitespace-nowrap transition ${
                        activeTab === tabKey
                            ? "text-[#7C80FE] border-b-2 border-[#7C80FE]"
                            : "text-[#02091C] hover:text-[#7C80FE]"
                        }`}
                    >
                        {formatTabName(tabKey)}
                    </button>
                    ))}
                </div>
                {/* Tab Content */}
                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-6 mt-8">
                    {data[activeTab].map((item) => (
                        <div
                            key={item.id}
                            className="p-4 flex items-center justify-start gap-3"
                            onMouseEnter={() => setHoveredItemId(item.id)}
                            onMouseLeave={() => setHoveredItemId(null)}
                        >
                            <Image src={hoveredItemId === item.id ? '/images/home/item-solution-hover.svg' : '/images/home/item-solution.svg'}
                                alt="logo"
                                width={14}
                                height={16}
                                quality={true}
                                priority={100}
                                className="w-[14px] h-4 object-cover" 
                            />
                            <Link href={"#"} className="">
                                <p className={`${hoveredItemId === item.id ? 'text-[#7C80FE]' : 'text-[#02091C]'} font-medium text-lg hover: transition`}>{item.title}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
export default BlocksSolution;