import LinkGradient from "@/components/link-button/link-gradient";
import LinkWhite from "@/components/link-button/link-white";
import Image from "next/image";

const SliderItem = ({ id, imageSrc, title, description, buttonText }) => {
  return (
    <div className="relative w-full h-[167px] md:h-[640px] keen-slider__slide">
      {/* Background Image */}
      <Image
        src={imageSrc}
        alt={title}
        className="absolute inset-0 w-full h-auto object-cover z-0"
        width={1920}
        height={640}
        quality={100}
        priority={false}
        loading="lazy"
      />
      {/* Content */}
      <div className="absolute p-4 md:top-48 left-0 right-0 z-10">
        <div className="container mx-auto flex flex-col items-start">
          <div className="md:max-w-[500px]">
            <h1 className="font-black text-lg md:text-5xl text-white mb-2 md:mb-4">{title}</h1>
            <p className="text-white font-medium text-sm md:text-lg md:mb-12">{description}</p>
            {id != 5 ? <LinkGradient title={buttonText} myClass="hidden md:flex"/> : <LinkWhite title={buttonText} myClass="hidden md:flex"/>}
          </div>
        </div>
      </div>
    </div>
  );
}
export default SliderItem;