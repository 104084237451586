'use client'

import { useKeenSlider } from 'keen-slider/react';
import { useState } from "react";
import SliderItem from "./banner-item";

const Banner = () => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [sliderRef, instanceRef] = useKeenSlider(
        {
            loop: true,
            initial: 0,
            slideChanged(slider) {
                setCurrentSlide(slider.track.details.rel)
            },
            created() {
                setLoaded(true)
            },
        },
        [
            (slider) => {
                let timeout
                let mouseOver = false
                function clearNextTimeout() {
                    clearTimeout(timeout)
                }
                function nextTimeout() {
                    clearTimeout(timeout)
                    if (mouseOver) return
                    timeout = setTimeout(() => {
                        slider.next()
                    }, 7000)
                }
                slider.on("created", () => {
                    slider.container.addEventListener("mouseover", () => {
                        mouseOver = true
                        clearNextTimeout()
                    })
                    slider.container.addEventListener("mouseout", () => {
                        mouseOver = false
                        nextTimeout()
                    })
                    nextTimeout()
                })
                slider.on("dragStarted", clearNextTimeout)
                slider.on("animationEnded", nextTimeout)
                slider.on("updated", nextTimeout)
            },
        ]
    )

    const sliderData = [
        {
            id: 1,
            imageSrc: "/images/banner/home-banner-1.jpg",
            title: "Dịch vụ Kho bãi",
            description: "Với hệ thống kho được đầu tư hiện đại và dịch vụ tích hợp đạt tiêu chuẩn cao, vị trí thuận lợi cùng với giải pháp công nghệ quản lý thông minh sẽ giúp doanh nghiệp bạn tối ưu chi phí",
            buttonText: "Xem chi tiết",
        },
        {
            id: 2,
            imageSrc: "/images/banner/home-banner-2.jpg",
            title: "Phân hàng tự động",
            description: "Tương lai của ngành Logistics nằm ở sự tự động hoá thông minh. Tăng hiệu suất phân hàng tự động rất nhanh với độ chính xác cao, tối ưu chi phí lao động",
            buttonText: "Xem chi tiết",
        },
        {
            id: 3,
            imageSrc: "/images/banner/home-banner-3.jpg",
            title: "Tư vấn giải pháp - Thi công toàn diện",
            description: "Bạn đang tìm kiếm giải pháp vận hành tối ưu cho doanh nghiệp vận chuyển? Chào mừng bạn đã đến với chúng tôi. Ngoài tư vấn chúng tôi còn thực hiện thi công lắp đặt, đào tạo và hổ trợ kỹ thuật hiệu quả.",
            buttonText: "Xem chi tiết",
        },
        {
            id: 4,
            imageSrc: "/images/banner/home-banner-4.jpg",
            title: "Dịch vụ Nhập hàng",
            description: "Dịch vụ nhập hàng giúp doanh nghiệp vận chuyển và đưa hàng hóa từ nước ngoài về, bao gồm các bước tìm nguồn, vận chuyển, thủ tục hải quan và giao nhận, giúp tiết kiệm thời gian và chi phí.",
            buttonText: "Xem chi tiết",
        },
        {
            id: 5,
            imageSrc: "/images/banner/home-banner-5.jpg",
            title: "Thiết bị",
            description: "Chúng tôi cung cấp cách thiết bị hổ trợ quét mã vạch thông minh như iDATA, FIRD được thiết kế hiện đại với độ chính xác và khả năng nhận diện cao.",
            buttonText: "Xem chi tiết",
        },
    ];

    return (
        <>
            <div className="relative">
                {/* Dots */}
                {loaded && instanceRef.current && (
                    <div className="dots absolute bottom-2 md:bottom-6 left-1/2 transform -translate-x-1/2 z-10 flex space-x-2">
                        {[
                            ...Array(instanceRef.current.track.details.slides.length).keys(),
                        ].map((idx) => {
                            return (
                                <button
                                    key={idx}
                                    onClick={() => {
                                        instanceRef.current?.moveToIdx(idx)
                                    }}
                                    className={"dot" + (currentSlide === idx ? " active" : "")}
                                ></button>
                            )
                        })}
                    </div>
                )}

                {/* Slider */}
                <div ref={sliderRef} className="keen-slider">
                    {sliderData.map((item) => (
                        <SliderItem key={item.id} {...item} />
                    ))}
                </div>

            </div>
        </>
      )
}
export default Banner;